import React, { PureComponent } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Link from 'next/link'
import Head from 'next/head'
import { connect, ConnectedProps } from 'react-redux'
import FreelancerFavouriteSidebar from 'components/freelancers/FreelancerFavouriteSidebar'
import { HOST } from 'utils/helpers'
import Freelancer from 'components/freelancers/Freelancer'
import FreelancerLoader from 'components/freelancers/FreelancerLoader'
import NotificationBar from 'components/NotificationBar'
import { IProfile } from 'redux/interfaces/profile'
import { ICompany } from 'redux/interfaces/company'
import { getRandomByCategory, trackProfileView } from 'api/profile'
import Button from 'components/Button'
import Tooltip from 'components/Tooltip'
import FreelancerUtils from 'src/utils/freelancer'
import TrackingUtils from 'utils/tracking'
import Translate from 'translations/Translate'
import Emoji, { EmojiWithContent } from 'components/Emoji'
import ImageUtils from 'utils/image'
import ProFreelancer from 'components/upsell/ProFreelancer'
import OgUtils from 'utils/og'
import VideoPlayer from 'components/VideoPlayer'
import FreelancerFavourite from 'components/freelancers/FreelancerFavourite'
import FreelancerDetailTags from 'pages/freelancers/components/FreelancerDetailTags'
import ContactModal from './components/ContactModal'
import SocialLinks from './components/SocialLinks'
import ProfilePicture from './components/ProfilePicture'
import type { IStoreState } from 'redux/reducers'

interface IOwnProps {
  profile?: IProfile
  company?: ICompany
  query: { shortId: string }
}

interface IState {
  loading: boolean
  similar: IProfile[] | null
  profilesCount: number | null
  contactModalVisible: boolean
  contactSuccess: boolean
  contactDate: string | null
}

type IProps = IOwnProps & PropsFromRedux

class Index extends PureComponent<IProps, IState> {
  state: IState = {
    loading: true,
    similar: null,
    profilesCount: null,
    contactModalVisible: false,
    contactSuccess: false,
    contactDate: null,
  }

  init = async () => {
    const { profile } = this.props
    let similar = null

    if (profile) {
      similar = await getRandomByCategory(profile.job_category._id, profile.shortId)
      await trackProfileView(profile.shortId)
    }

    TrackingUtils.dataLayerPush(
      'offerdetail',
      TrackingUtils.getUnifiedFreelancerTrackingData(profile)
    )

    this.setState({
      loading: false,
      similar: similar ? similar.profiles : null,
      profilesCount: similar ? similar.profilesCount : null,
    })
  }

  async componentDidMount() {
    await this.init()
  }

  async componentDidUpdate(prevProps: IProps) {
    if (prevProps.profile !== this.props.profile) {
      await this.init()
    }
  }

  onContactClick = async (e) => {
    e.preventDefault()

    TrackingUtils.dataLayerPush(
      'interest',
      TrackingUtils.getUnifiedFreelancerTrackingData(this.props.profile)
    )

    this.setState({ contactModalVisible: true, contactSuccess: false })
  }

  getOgTitle = () => {
    const { profile } = this.props
    return `${profile.name} – ${profile.title} | Pretlak.com`
  }

  hideContactModal = (e) => {
    e.preventDefault()
    this.setState({ contactModalVisible: false })
  }

  hideContactWithSuccess = () => {
    this.setState({
      contactModalVisible: false,
      contactSuccess: true,
      contactDate: this.props.company ? dayjs().format('DD.MM.YYYY') : null,
    })
  }

  getLastContactedDate = () => {
    const { profile, company } = this.props
    const contactLogs = profile?.contactLogs || []
    const companyContactLog = contactLogs.filter(
      (log) => log.company === company?.shortId
    )

    return companyContactLog.length > 0
      ? dayjs(companyContactLog[companyContactLog.length - 1].date).format('DD.MM.YYYY')
      : this.state.contactDate
  }

  render() {
    const { profile } = this.props
    const { similar, profilesCount, loading, contactModalVisible, contactSuccess } =
      this.state

    const imageId = OgUtils.getLogoIdFromUrl(profile?.picture)

    return (
      <Translate>
        {(t, { locale }) => (
          <>
            <Head>
              <title>{this.getOgTitle()}</title>

              {!profile.available && <meta name="robots" content="noindex" />}

              <meta key="title" property="og:title" content={this.getOgTitle()} />
              <meta
                key="description"
                property="og:description"
                content={FreelancerUtils.getMetaDescription(profile)}
              />
              <meta
                key="image"
                property="og:image"
                content={`${HOST}/api/og-freelancer?imageId=${imageId}&category=${profile.job_category.slug}&locale=${locale}`}
              />
              <meta
                key="title:twitter"
                name="twitter:title"
                content={this.getOgTitle()}
              />
              <meta
                key="description:twitter"
                name="twitter:description"
                content={FreelancerUtils.getMetaDescription(profile)}
              />

              <meta
                key="twitter:image"
                name="twitter:image"
                content={`${HOST}/api/og-freelancer?imageId=${imageId}&category=${profile.job_category.slug}&locale=${locale}`}
              />
            </Head>

            <ContactModal
              contactDate={this.getLastContactedDate()}
              profile={profile}
              visible={contactModalVisible}
              onClose={this.hideContactModal}
              onSuccess={this.hideContactWithSuccess}
            />

            {contactSuccess && (
              <NotificationBar
                emoji="✅"
                text={t('freelancers.contactToFreelancerInMail')}
              />
            )}

            <div
              className={classNames('heading-wrapper job-detail', {
                'job-detail--dark-bg-variant':
                  ImageUtils.getContrast(profile.backgroundColor) === 'white',
              })}
              style={{ backgroundColor: profile.backgroundColor }}
            >
              <div className="container job-detail__flex-wrapper job-detail__flex-wrapper--head job--level-2">
                <ProfilePicture profile={profile} />
                <div className="job-detail__header-left text-left">
                  {FreelancerUtils.isPremium(profile) && (
                    <Link href="/freelancers?pro=true" legacyBehavior>
                      <span className="pill pill--link">
                        <Emoji>{t('freelancers.pro.label')}</Emoji>
                      </span>
                    </Link>
                  )}
                  {profile.available && (
                    <Link href="/freelancers?available=true" legacyBehavior>
                      <span className="pill pill--green pill--link">
                        {t('label.available')}
                      </span>
                    </Link>
                  )}
                  {!profile.available && (
                    <span className="pill pill--gray-dark">{t('label.unavailable')}</span>
                  )}
                  {profile.addons?.topSponsoredStart && (
                    <span className="pill pill--white">
                      {t('label.sponsoredFreelancer')}
                    </span>
                  )}
                  <h2 className="job-detail__heading-1 text-left">
                    <Emoji>{profile.name}</Emoji>
                  </h2>
                  <h3 className="job-detail__heading-2 text-left">
                    <Emoji>{profile.title}</Emoji>
                  </h3>
                </div>

                <div className="job-detail__header-right text-left">
                  <p className="job__info">
                    <span className="job__info--light">{t('common.salaryPerHour')}:</span>{' '}
                    {profile.job_salary_min} — {profile.job_salary_max} €
                    {profile.job_salary_note && profile.job_salary_note.length > 0 && (
                      <Tooltip content={profile.job_salary_note} />
                    )}
                  </p>

                  <p className="job__info">
                    <span className="job__info--light">{t('filter.skillLevel')}:</span>{' '}
                    <Emoji>
                      {t('freelancers.skillLevel.plain.' + profile.skill_level.slug)}
                    </Emoji>
                  </p>

                  <p data-cy="freelancer-skill-level" className="job__info">
                    <span className="job__info--light">{t('common.dateSignup')}:</span>{' '}
                    <Emoji>{dayjs(profile.signup_date).format('DD.MM.YYYY')}</Emoji>
                  </p>

                  {profile.availability_status && (
                    <p data-cy="freelancer-skill-level" className="job__info">
                      <span className="job__info--light">
                        {t('common.availability')}:
                      </span>{' '}
                      {t('freelancers.availabilityStatus.' + profile.availability_status)}
                      {profile.full_time_available && (
                        <>
                          {', '}
                          {t('freelancers.openToFullTimeShort')}
                        </>
                      )}
                      <span className="pill pill--super-small pill--margin-left-medium pill--uppercase pill--green">
                        {t('common.new')}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="container job-detail__flex-wrapper margin-top-super-small margin-bottom">
              <div className="job-detail__content-left">
                <FreelancerDetailTags profile={profile} />
                {profile.detail.promoVideo && (
                  <div className="margin-bottom-20">
                    <VideoPlayer url={profile.detail.promoVideo} />
                  </div>
                )}
                {profile.detail.about && (
                  <EmojiWithContent content={profile.detail.about} />
                )}
                {profile.detail.education && (
                  <>
                    <h3 className="heading-3 margin-top">{t('common.education')}</h3>
                    <EmojiWithContent content={profile.detail.education} />
                  </>
                )}
                {profile.detail.portfolio && (
                  <>
                    <h3 className="heading-3 margin-top">{t('common.portfolio')}</h3>
                    <EmojiWithContent content={profile.detail.portfolio} />
                  </>
                )}
                {profile.detail.awards && (
                  <>
                    <h3 className="heading-3 margin-top">
                      {t('common.certificationTitle')}
                    </h3>
                    <EmojiWithContent content={profile.detail.awards} />
                  </>
                )}

                {FreelancerUtils.isPremium(profile) && (
                  <ProFreelancer profile={profile} />
                )}
              </div>

              <div className="job-detail__content-right job-detail__content-right--with-sticky-mobile-content">
                <div className="sticky padding-top-20">
                  <div className="job-detail__tablet-hidden">
                    {this.getLastContactedDate() && (
                      <div className="info-box-small info-box--yellow-soft margin-bottom-small">
                        <Emoji>⚠️</Emoji>{' '}
                        {t('freelancers.freelancerContactedShort', {
                          date: this.getLastContactedDate(),
                        })}
                      </div>
                    )}
                    <div className="margin-bottom-small">
                      <Button
                        text={t('freelancers.contact')}
                        onClick={this.onContactClick}
                        size="full"
                        color="green"
                      />
                    </div>

                    <FreelancerFavouriteSidebar profile={profile} />
                  </div>

                  {FreelancerUtils.hasSocialLinks(profile.detail) && (
                    <div className="profile--gray-box margin-bottom-small">
                      <SocialLinks urls={profile.detail} />
                    </div>
                  )}

                  {/*<div*/}
                  {/*  className={classNames('job-detail__content-right-banner', {*/}
                  {/*    'margin-top-small': !FreelancerUtils.hasSocialLinks(profile.detail),*/}
                  {/*  })}*/}
                  {/*>*/}
                  {/*  <Link href="/freelancers/pro">*/}
                  {/*    <Image*/}
                  {/*      src={`/images/freelancers/profile/freelancers-pro-banner-v2.png`}*/}
                  {/*      width={300}*/}
                  {/*      height={600}*/}
                  {/*      alt=""*/}
                  {/*      quality={100}*/}
                  {/*    />*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                </div>
              </div>

              <div className="job-detail__tablet-sticky">
                {this.getLastContactedDate() && (
                  <div className="info-box-small width-full center info-box--yellow-soft">
                    <Emoji>⚠️</Emoji>{' '}
                    {t('freelancers.freelancerContactedShort', {
                      date: this.getLastContactedDate(),
                    })}
                  </div>
                )}
              </div>

              <div className="job-detail__tablet-sticky">
                <Button
                  text={t('freelancers.contact')}
                  onClick={this.onContactClick}
                  size="full"
                  color="green"
                />

                <FreelancerFavourite profile={profile} defaultStyle="gray" fixedSize />
              </div>
            </div>

            {!loading && similar && similar.length > 0 && (
              <div className="freelancers__similar">
                <div className="container">
                  <Link
                    href={`/freelancers?jobCategories=${profile.job_category.slug}`}
                    className="heading-3"
                  >
                    {t('freelancers.next')} ({profilesCount})
                  </Link>
                </div>

                <div>
                  {similar.map((profile) => (
                    <Freelancer key={profile.shortId} profile={profile} />
                  ))}
                </div>

                <div className="freelancers__similar-button-wrapper container center">
                  <p>
                    <Link
                      href={`/freelancers?jobCategories=${profile.job_category.slug}`}
                      className="button button-simple button--black"
                    >
                      <span className="button__content button--desktop-content">
                        {t(
                          `freelancers.showAllCategoryWithCount[${profile.job_category.slug}]`,
                          {
                            count: profilesCount,
                          }
                        )}
                      </span>
                      <span className="button__content button--mobile-content">
                        {t(`freelancers.showAllCount`, {
                          count: profilesCount,
                        })}
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
            )}

            {loading && (
              <div className="freelancers__similar">
                <div className="container">
                  <span className="loader__heading-3"></span>
                </div>
                <FreelancerLoader dark />
                <FreelancerLoader dark />
                <FreelancerLoader dark />
              </div>
            )}
          </>
        )}
      </Translate>
    )
  }
}

const connector = connect(({ user, products, company }: IStoreState) => ({
  user,
  products,
  company,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Index)
