import { get, post } from 'utils/axios'

export const getRandomByCategory = async (categoryId: string, shortId: string) => {
  try {
    const { data } = await get(`api/profile/get-random/${categoryId}/${shortId}`)

    if (data.status === 'ok') {
      return { success: true, profiles: data.profiles, profilesCount: data.count }
    } else {
      return { success: false }
    }
  } catch (err) {
    return { success: false }
  }
}

export const deleteProfile = async (companyData: any) => {
  try {
    const { data } = await post('api/profile/delete/', companyData)

    if (data.status === 'ok') {
      return { success: true }
    } else {
      return { success: false }
    }
  } catch (err) {
    return { success: false }
  }
}

export const trackProfileView = async (shortId: string) => {
  try {
    const { data } = await post(`api/profile/${shortId}/track-view/`)

    if (data.status === 'ok') {
      return { success: true }
    } else {
      return { success: false }
    }
  } catch (err) {
    return { success: false }
  }
}

export const trackProfileContact = async (
  shortId: string,
  trackData?: { email?: string; type?: string }
) => {
  try {
    const { data } = await post(`api/profile/${shortId}/track-contact/`, trackData)

    if (data.status === 'ok') {
      return { success: true }
    } else {
      return { success: false }
    }
  } catch (err) {
    return { success: false }
  }
}

export const contactFreelancer = async (data: {
  email: string
  message: string
  newsletter: boolean
  profileShortId: string
  companyShortId?: string
  lang: string
}) => {
  try {
    const { data: responseData } = await post(`api/profile/contact-via-email/`, data)

    if (responseData.status === 'ok') {
      return { success: true }
    } else {
      return { success: false }
    }
  } catch (err) {
    return { success: false }
  }
}
