import React, { PureComponent } from 'react'
import { parseCookies, setCookie } from 'nookies'
import { connect } from 'react-redux'
import Link from 'next/link'
import Router from 'next/router'
import Checkbox from 'components/form/Checkbox'
import { contactFreelancer, trackProfileContact } from 'api/profile'
import { IProfile } from 'redux/interfaces/profile'
import FullSizeModal from 'components/modals/FullSizeModal'
import { isEmailValid } from 'utils/form'
import TrackingUtils from 'utils/tracking'
import EmailInput from 'components/form/Email'
import Button from 'components/Button'
import Textarea from 'components/form/Textarea'
import Translate from 'translations/Translate'
import Emoji from 'components/Emoji'
import type { ICompany } from 'redux/interfaces/company'

// 1 months
const COOKIES_LENGTH = 60 * 60 * 24 * 31
interface IProps {
  profile: IProfile
  loggedCompany: ICompany
  loggedProfile: IProfile
  visible: boolean
  onClose: (e, hideContactModal?: boolean) => any
  onSuccess: () => any
  contactDate?: string | null
}

interface IState {
  loading: boolean
  data: {
    email: string
    message: string
    newsletter: boolean
  }
  error: boolean
  textareaError: boolean
  emailError: boolean
}

class ContactModal extends PureComponent<IProps, IState> {
  state: IState = {
    loading: false,
    data: {
      email: parseCookies()['freelancer_email'] || '',
      message: '',
      newsletter: false,
    },
    error: false,
    textareaError: false,
    emailError: false,
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.visible && !prevProps.visible) {
      let currentEmail = this.state.data.email

      if (this.props.loggedCompany) {
        currentEmail = this.props.loggedCompany.email
      } else if (this.props.loggedProfile) {
        currentEmail = this.props.loggedProfile.email
      }

      this.setState({
        data: {
          ...this.state.data,
          email: currentEmail,
        },
      })

      TrackingUtils.event('freelancer-interest')
      TrackingUtils.transaction({
        transactionId: Date.now(),
        price: 0,
        priceWithoutTax: 0,
        tax: 0,
        items: [
          {
            type: 'FREELANCER_INTEREST',
            properties: { profile: this.props.profile },
          },
        ],
      })
    }
  }

  handleInput = (e) => {
    const { data } = this.state
    this.setState({
      data: { ...data, [e.target.name]: e.target.value },
    })
  }

  handleCheckbox = (e) => {
    const { data } = this.state
    this.setState({
      data: { ...data, [e.target.name]: e.target.checked },
    })
  }

  handleRadio = (e) => {
    const { data } = this.state
    this.setState({
      data: { ...data, [e.target.name]: e.target.value },
    })
  }

  validate = () => {
    const { data } = this.state
    let isValid = true

    let emailError = false
    if (!data.email || !isEmailValid(data.email)) {
      emailError = true
      isValid = false
    }

    let textareaError = false

    if (!data.message) {
      textareaError = true
      isValid = false
    }

    this.setState({
      textareaError,
      emailError,
    })

    return isValid
  }

  getUserType = () => {
    const { loggedCompany, loggedProfile } = this.props

    if (loggedCompany) {
      return 'company'
    } else if (loggedProfile) {
      return 'freelancer'
    }

    return 'user'
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({ loading: true, error: false }, async () => {
      if (!this.validate()) {
        return this.setState({ loading: false, error: true })
      }

      const { profile } = this.props
      const { data } = this.state
      const { success } = await contactFreelancer({
        email: data.email,
        message: data.message,
        newsletter: data.newsletter,
        profileShortId: profile.shortId,
        companyShortId: this.props.loggedCompany?.shortId,
        lang: Router?.locale,
      })

      if (success) {
        const isPaidCompany = this.props.loggedCompany?.hasUnlockedFreelancers ?? false

        await trackProfileContact(profile.shortId, {
          email: data.email,
          type: this.getUserType(),
        })

        TrackingUtils.event('freelancer-contact', {
          event_action: profile.shortId,
          event_data1: `profileCategory: ${profile.job_category.slug}`,
          event_data2: isPaidCompany ? 'paid' : 'not-paid',
          event_data3: `companyType: ${this.getUserType()}`,
          event_data4: `freelancerEmail: ${profile.email}`,
          freelancerShortId: profile.shortId,
          freelancerCategory: profile.job_category.slug,
          companyType: this.getUserType(),
        })

        TrackingUtils.transaction({
          transactionId: Date.now(),
          price: 0,
          priceWithoutTax: 0,
          tax: 0,
          items: [
            {
              type: isPaidCompany
                ? 'FREELANCER_CONTACT_PAID'
                : 'FREELANCER_CONTACT_NOT_PAID',
              properties: {
                profile: this.props.profile,
                companyType: this.getUserType(),
              },
            },
          ],
        })

        const cookieOptions = {
          maxAge: COOKIES_LENGTH,
          path: '/',
        }

        if (parseCookies()['complianceCookie'] === 'yes') {
          setCookie(null, 'freelancer_email', data.email, cookieOptions)
        }

        this.props.onSuccess()
        this.setState({ loading: false })
      } else {
        this.setState({ loading: false, error: !success })
      }
    })
  }

  render() {
    const { profile, visible, onClose, contactDate } = this.props
    const { data, error, emailError, textareaError, loading } = this.state

    if (!visible) {
      return null
    }

    return (
      <Translate>
        {(t) => (
          <FullSizeModal
            visible
            title={t('freelancers.contact')}
            description={
              <strong>
                {profile.name}, {profile.title}
              </strong>
            }
            onClose={onClose}
          >
            {contactDate && (
              <div className="info-box-small info-box--yellow-soft margin-bottom-small center">
                <Emoji>⚠️</Emoji>{' '}
                {t('freelancers.freelancerContacted', {
                  date: contactDate,
                })}
              </div>
            )}
            <form onSubmit={this.handleSubmit} name="freelancer-contact">
              <EmailInput
                label={t('freelancers.contactModal.emailLabel')}
                placeholder={t('freelancers.contactModal.emailPlaceholder')}
                name="email"
                value={data.email}
                onChange={this.handleInput}
                error={emailError}
              />

              <Textarea
                name="message"
                label={t('freelancers.contactModal.textareaLabel')}
                placeholder={t('freelancers.contactModal.textareaPlaceholder')}
                value={data.message}
                onChange={this.handleInput}
                error={textareaError}
                required
              />

              <Checkbox
                withPadding
                multilineNext
                withBackground
                type="upsell-yellow"
                label={
                  <span>
                    {t('common.iAgreeWith.start1')}{' '}
                    <Link href="/suhlas-newsletter" target="_blank">
                      {t('common.iAgreeWith.subject1')}
                    </Link>{' '}
                    {t('common.iAgreeWith.purpose1')}
                  </span>
                }
                name="newsletter"
                checked={data.newsletter}
                onChange={this.handleCheckbox}
              />

              <div className="container center">
                {error && !emailError && !textareaError && (
                  <p className="paragraph paragraph--orange paragraph--bold margin-bottom-small">
                    {t('common.error.errorDuringProcesingTryAgain')}
                  </p>
                )}
              </div>

              <div className="margin-top-small margin-bottom">
                <Button
                  text={t('common.actions.send')}
                  loading={loading}
                  color="green"
                  size="full"
                />
              </div>
            </form>
          </FullSizeModal>
        )}
      </Translate>
    )
  }
}

const mapStateToProps = ({ profile, company }) => ({
  loggedCompany: company,
  loggedProfile: profile,
})

export default connect(mapStateToProps)(ContactModal)
