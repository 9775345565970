import Link from 'next/link'
import React, { FC } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import ExpandableTags from 'components/ExpandableTags'
import { IProfile } from 'redux/interfaces/profile'
import useTranslation from 'translations/hooks/useTranslation'

type IProps = {
  profile: IProfile
}

const MOBILE_TAGS_LIMIT = 9

const FreelancerDetailTags: FC<IProps> = ({ profile }) => {
  const router = useRouter()
  const { t } = useTranslation()

  const getTagName = (tag) => {
    if (tag.slug === 'sng') {
      return t('sng.tag.sng')
    }

    return tag.name
  }

  return (
    <>
      {profile.tags && profile.tags.length > 0 && (
        <div className="job-detail__content-pills">
          <Link
            href={`/freelancers?jobCategories=${profile.job_category.slug}`}
            className="pill pill--mobile-small pill--tag pill--link"
          >
            {profile.job_category.name}
          </Link>

          <ExpandableTags
            tags={profile.tags}
            identifier="frelancer-tag"
            onTagClick={(tag) => router.push(`/freelancers?tags=${tag}`)}
            className="freelancer__detail-tags-mobile"
            limit={MOBILE_TAGS_LIMIT}
          />
          <div className="freelancer__detail-tags-desktop">
            {profile.tags.map((tag) => {
              if (tag) {
                return (
                  <Link
                    key={tag._id}
                    href={`/freelancers?tags=${tag.slug}`}
                    legacyBehavior
                  >
                    <span
                      className={classNames(
                        'pill pill--mobile-small pill--tag pill--link',
                        {
                          'pill--sng': tag.slug === 'sng',
                        }
                      )}
                    >
                      {getTagName(tag)}
                    </span>
                  </Link>
                )
              }
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default FreelancerDetailTags
